import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ParalaxTitle from '../components/ParalaxTitle';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import imgBackground from '../images/3.jpg';
import BookCard from '../components/BookCard';
import TitleBgFilled from '../components/TitleBgFilled';

export default function Books() {
  const sagasAndBooks = useStaticQuery(graphql`
    query sagasAndBooks {
      allContentfulLibros {
        edges {
          node {
            id
            book_state
            name
            book_image {
              gatsbyImageData
            }
            slug
            synopsis {
              synopsis
            }
            booktrailer_url
          }
        }
      }
      allSagas {
        edges {
          node {
            saga_id
            title
          }
        }
      }
    }
  `);

  const books = sagasAndBooks.allContentfulLibros.edges;
  const sagas = sagasAndBooks.allSagas.edges;
  return (
    <Layout>
      <SEO title="Libros" />
      <ParalaxTitle text="Libros" imgBackground={imgBackground} />
      <Container>
        {sagas.map((saga, indexSaga) => (
          <div key={`${saga}-${indexSaga}`}>
            <TitleBgFilled className="mt-4" text={saga.node.title} />
            <Row className="pt-3 justify-content-center">
              {books.map((book, index) => (
                <BookCard key={`${book}-${index}`} book={book.node} />
              ))}
            </Row>
          </div>
        ))}
      </Container>
    </Layout>
  );
}
