import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledTitleBgFilled = styled.div`
  text-align: center;
  text-decoration: none;
`;

interface TitleBgFilledProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}

export default function TitleBgFilled({ text, ...rest }: TitleBgFilledProps) {
  return (
    <StyledTitleBgFilled {...rest}>
      <Typography variant="h4" py={2} color="white" bgcolor="#111111b3" textTransform="capitalize">
        {text}
      </Typography>
    </StyledTitleBgFilled>
  );
}
