import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Col } from "react-bootstrap"
import styled from "styled-components"

const StyledBookCard = styled.div`
  .book-card {
    transition: 0.4s;
    &:hover {
      transform: scale(1.1);
    }
  }
`

export default function BookCard({ book }) {
  const imageData = getImage(book.book_image) as IGatsbyImageData
  return (
    <StyledBookCard>
      <Col style={{ width: 230 }}>
        <Link
          to={`/libros/${book.slug}/`}
          className="text-decoration-none text-reset"
        >
          {/* TODO Create an alt text for images */}
          <GatsbyImage image={imageData} alt="" className="book-card mb-4" />
        </Link>
      </Col>
    </StyledBookCard>
  )
}
